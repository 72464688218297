@import "../../../variables.module.scss";



.headerTitle {

    .sticky {

        .aboutUs{
            text-align: center;

            h6{
                margin: 0px;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
  }

  .bgShape {
      width:100%;
      display: none;
  }
}


.contentBody {
  padding:20px 20px;
  margin-top: 60px !important;

  .notificatBox{
    background: #fff;
    box-shadow: 0px 3px 8px #9e9e9e29;
    border-radius: 7px;
    padding: 12px 12px 12px 16px;
    display: flex;
    border-left: 8px solid #B55B5B;
    margin-bottom: 16px;

    .notificatImg{
        img{
            width: 47px;
            height: 47px;
            margin-right: 11px;
        }
    }
    .notificationText{
        width: 100%;
        
        .notificationTitle{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            h6{
                font-size: 17px;
                font-weight: 700;
                margin: 0;
            }
        }
        p{
            font-size: 14px;
            color: #818181;
            margin: 0;
        }
    }
  }

  h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
  }
}
.notificatOne{
    border-color: rgb(176, 48, 255) !important;
}
.notificatTwo{
    border-color: rgb(202, 30, 48) !important;
}
.notificatThree{
    border-color: rgb(0, 66, 166) !important;
}
.notificatFor{
    border-color: rgb(141, 183, 2) !important;
}
.notificatFive{
    border-color: rgb(193, 139, 3) !important;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape {
        display: block !important;
    }
    .contentBody{
        margin-top: 0px !important;
    }
    .notificatBox{
        margin: 8px 12px;
        margin-bottom: 0 !important;
    }

}
