@import "../../../variables.module.scss";



.headerTitle {

    .sticky {
        position: sticky;
        top: 0;
        z-index: 99999;
        min-height: 60px;
        background: var(--Main);
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
      .skip {
          color:#fff !important;
          text-decoration: none;
      }
  }

  .bgShape {
      width:100%;
      object-fit: cover;
  }
  .logo {
      height:60px;
  }
}

.contentBody {
  padding:20px 20px;

  h5 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 4px;
  }
}

.otpBox {
    display:flex;
    margin-top: 40px;

    input[type="number"] {
        width: 60px;
        height: 60px;
        margin-right:25px;
        border-radius: 5px;
        border: 1px solid #ffffff;
        box-shadow: 0 0px 4px 1px #ebebeb;
        padding:0px;
        text-align: center;
        font-size: 22px;
        

        &:focus {
            box-shadow: 0 0px 4px 1px #ebebeb;
            border: 1px solid #ffffff;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
    :global(.form-control){
        margin-right: 30px !important;
        width: 70px;
        height: 60px;
        justify-content: center;
        border: 1.5px solid #375dbc;
        box-shadow: none !important;
    }
    input{
        border: none;

        &:focus-visible {
            outline: none !important;
        }
    }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape {
        height: 110px;
    }
    .otpBox {
    
        :global(.form-control){
            margin-right: 22px !important;
        }
    }
}
