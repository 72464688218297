@import "../../../variables.module.scss";

.bottomMenu {
    position: fixed;
    bottom: 0;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    width: 398px;
    z-index: 999;
    background: #fff;
    padding: 12px 0px;
    display: none;
    left: 0px;

    .menu {
        text-align:center;
        text-decoration:none;
        color:var(--Color) !important;
        display: block;

        .icon {
            height:20px;
            margin-bottom: 6px;
            filter: invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(24%) contrast(0);
        }

        h5 {
            font-size: 15px;
            margin-bottom:0px;
        }

        &:global(.active) {
            color:var(--Main) !important;

            .icon {
                filter:none;
            }
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .bottomMenu {
        width: 100%;
        display: block !important;
        
    }
}

