@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

      .wallet {
        font-size: 23px;
        line-height: 0px;
        margin-left:15px;
      }
      .notification {
        font-size: 27px;
        position: relative;

        span {
            position: absolute;
            font-size: 8px;
            right: -3px;
            top: 9px;
            border: 2px solid #ffffff;
            background: var(--Main);
            display: inline-block;
            width: 14px;
            height: 14px;
            line-height: 11px;
            color: #fff;
            border-radius: 50px;
            text-align: center;
            font-weight: 500;
        }
      }

      .searchBox {
          position:relative;
          display:flex;
          margin: 0px 10px 0px 0px;
          width: 100%;

          .icon {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 20px;
            top: 50%;
            cursor: pointer;
            font-size: 20px;
            color:var(--Main);
          }
          :global(.form-control) {
            border: 1px solid #dfd9d9;
            box-shadow: none;
            border-radius: 10px;
            height: 40px;
            line-height: 40px;
            padding: 0px 15px 0px 35px;
    
            &:focus {
                box-shadow: none;
                border: 1px solid var(--Main);
            }
        }

      }
  }

}

.checkMargin{
  margin-top: 100px;
  padding: 20px 20px;

  .checkBox{
    padding: 20px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EDEDED;

    input{
      width: 30px;
      height: 30px;
      margin-right: 16px;
    }

    ul{
      padding-left: 0px;
      list-style: none;
      margin-bottom: 0px;
      width: 100%;

      li{
        display: flex;
        justify-content: space-between;

        span{

          h5{
            color: #375DBC;
            font-weight: 400;
          }
        }
      }
    }
  }

  .applyBtn{
    display: flex;
    background: #375DBC;
    border-radius: 6px;
    height: 40px;
    color: #fff !important;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 32px !important;
    margin: 0 auto;
    width: 410px;
  }
}





/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .applyBtn{
    width: 100% !important;
  }
  .checkMargin{
    margin-top: 60px !important;
  }

}
