.headerStart {

    .headerTop {
        width: 100%;
        padding: 12px 22px;
        border-bottom: 1px solid #f1f1f1;
        box-shadow: 0px -1px 6px #00000029;
        position: fixed !important;
        z-index: 999;
        background: #fff;
        top: 0px;

        .titleBar {

            .logo {
                img {
                    width: 220px;
                    height: auto;
                }
            }

            .fixHeader {
                display: flex;
                align-items: center;
                justify-content: end;

                .gift {
                    font-size: 26px;
                    line-height: 0px;
                    margin-right: 18px;
                }

                .searchBox {
                    position: relative;

                    input {
                        height: 38px;
                        padding-left: 38px;
                    }

                    svg {
                        position: absolute;
                        left: 10px;
                        top: 9px;
                        font-size: 20px;
                        color: #375dbc;
                    }

                    :global(.form-control) {

                        &:focus {
                            box-shadow: none !important;
                            border: 1px solid var(--Main);
                        }
                    }

                    .searchListView {
                        box-shadow: 0 0px 4px 1px #e3e3e3;
                        background-color: #fff;
                        border-radius: 6px;
                        padding: 10px;
                        position: absolute;
                        top: 45px;
                        width: 100%;

                        ul {
                            padding-left: 0px;

                            li {
                                list-style: none;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin: 10px 0px;

                                a {
                                    text-decoration: none;

                                    svg {
                                        position: relative;
                                        top: 0px;
                                        right: 15px !important;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .notification {
                    font-size: 27px;
                    position: relative;
                    margin-left: 16px;

                    span {
                        position: absolute;
                        font-size: 8px;
                        right: -3px;
                        top: 9px;
                        border: 2px solid #ffffff;
                        background: var(--Main);
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        line-height: 11px;
                        color: #fff;
                        border-radius: 50px;
                        text-align: center;
                        font-weight: 500;
                    }
                }

                .wallet {
                    font-size: 23px;
                    line-height: 0px;
                    margin-left: 15px;
                    margin-right: 18px;
                }
            }

            .headerUser {
                text-align: center;

                .headerDrop {
                    background: transparent;
                    border: none;
                    padding: 0px;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        object-fit: cover;
                    }

                }

                .DropdownSelects {
                    min-width: 180px;
                    border: none;
                    box-shadow: 0px 0px 7px #58585829;
                    top: 8px !important;

                    a {
                        color: var(--Color) !important;
                        text-decoration: none;
                        padding: 6px 20px;
                        display: block;

                        img {
                            margin-right: 12px;
                            height: 16px;
                        }


                        .classCount {
                            background-color: #375dbc;
                            color: white;
                            border-radius: 50%;
                            padding: 3px 5px;
                            text-align: center;
                            min-width: 30px;
                        }
                    }
                }
            }


        }
    }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .headerStart {
        display: none !important;
    }
}