@import "../../../variables.module.scss";

.headerTitle {

    .sticky {

        .aboutUs {
            text-align: center;

            h6 {
                margin: 0px;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

    .titleBar {
        margin: 0;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 15px 5px;
        align-items: center;

        .back {
            color: #fff !important;
            font-size: 20px;
        }

    }

    .bgShape {
        width: 100%;
        object-fit: cover;
    }
}


.contentBody {
    .paddingLeft {
        padding: 20px 20px;
    }

    h5 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .profileText {
        display: flex;

        .userView {
            position: relative;
            margin: 0 auto;

            .userImg {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }

            .editIcon {
                position: absolute;
                bottom: 18px;
                right: -8px;
                overflow: hidden;
                cursor: pointer;

                input[type="file"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }

        }

        .userText {
            padding-left: 20px;

            p {
                margin-bottom: 0px;
                margin-top: 8px;
                color: #7F7F7F;
            }
        }
    }

    .profileEdit {
        margin-bottom: 16px;

        label {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 6px;
        }

        input {
            width: 100%;
            border-radius: 10px;
            padding: 12px 18px;
            border: 1px solid #e1e1e1;
        }

        input:focus {
            outline: none !important;
        }

        select {
            border-radius: 10px;
            padding: 12px 18px;
            border: 1px solid #e1e1e1;
        }

        select:focus {
            outline: none !important;
        }

        textarea {
            width: 100%;
            border-radius: 10px;
            padding: 12px 18px;
            border: 1px solid #e1e1e1;
        }

        textarea:focus {
            outline: none !important;
        }

        :global(.react-tel-input) {
            input {
                padding-left: 48px !important;
                height: auto !important;
            }
        }
    }

    .SubmitBtn {
        width: 100%;
        background: var(--Main) !important;
        color: #fff !important;
        height: 50px;
        line-height: 50px;
        border-radius: 10px;
        padding: 0px;
        margin-top: 16px;
    }

}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape {
        height: 110px;
    }
}