@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
      color: #000 !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.sliderHome {
  padding: 10px 0px 0px 0px;
}

.linkBtn {
  width: auto !important;
  background-color: var(--Main);
  color: #fff !important;
  width: 75px !important;
  padding: 5px;
  border-radius: 10px !important;
  text-align: center;
  text-decoration: none;

  a {
    color: #fff !important;
    background-color: var(--Main) !important;
  }
}

.detailsBox {
  background: #fff;
  box-shadow: 0px 0px 8px #9e9e9e29;
  border-radius: 8px;
  padding: 10px 0px;

  img {
    width: 100%;
    height: 420px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0px 10px;
  }

  p {
    color: #375DBC;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 10px;
  }

  .preferenceBox {
    border-bottom: 1px solid #EDEDED;
    padding: 10px;
    margin-bottom: 13px;

    ul {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 6px;

      h6 {
        color: #375DBC;
        font-size: 17px;
        margin-bottom: 12px;
        font-weight: 500;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .preferenceSelect {
    box-shadow: 0px 2px 12px #90909029;
    border-radius: 6px;
    background: #fff;
    margin: 10px;
    padding: 10px;

    h5 {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 12px;
      padding: 0px;
    }

    ul {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      margin-bottom: 8px;

      .themeColor {
        color: #375DBC;
      }
    }
  }
}

.contentBody {
  padding: 20px 20px;
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .detailsBox {
    padding: 10px 20px;

    img {
      height: auto !important;

    }

    .preferenceSelect {
      margin: 0px;

    }
  }



}