@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
      color: #000 !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.spaceBox {
  margin-top: 50px;
}

.classCard {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  // margin: 20px 0px 20px 0px;
  height: 100%;

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;
    border: none;
    margin-bottom: 12px;

    &:focus,
    &:hover,
    &.active {
      background-color: var(--Main2) !important;
      box-shadow: none;
      border: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 10px;

    li {
      display: flex;
      justify-content: space-between;

      .profileName {
        display: flex;
        align-items: center;
        gap: 11px;
        cursor: pointer;

        .imgClass {
          width: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 50%;
        }

        .profileName_details {
          h5 {
            margin-bottom: 0;
            line-height: 20px;
            font-size: 19px;
          }

          :global(.btn) {
            font-size: 11px;
            width: auto;
            padding: 2px 6px;
            border-radius: 4px;
            margin-bottom: 0px;
          }
        }
      }

      span:first-child {
        // padding-right: 15px;
        // width: 50%;

        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          max-width: 156px;

          button {
            border: none;
            background: none;
            transition: .2s;
            padding: 0;

            &:hover {
              color: #375dbc;
            }
          }
        }
      }

      .linkBtn {
        width: auto !important;

        a {
          color: #fff !important;
        }
      }
    }
  }
}

.menuList {
  background: var(--Main3);
  padding: 0px 0px;

  :global(.nav) {


    :global(.nav-item) {
      width: 33.33%;

      :global(.nav-link) {
        background-color: transparent;
        padding: 15px 10px;
        width: 100%;
        display: block;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0px;
        text-align: center;
        cursor: pointer;

        &:global(.active) {
          border-bottom: 2px solid var(--Main);
        }
      }
    }
  }
}

.contentBody {
  padding: 20px 20px;
}



.popupBox {

  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
      height: 490px;
      overflow: auto;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: fixed;
    z-index: 9999;
    width: 398px !important;
    top: 50% !important;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }

    .closeIcon {
      position: absolute;
      right: 0px;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }

    .popupTitle {
      border-bottom: 1px solid #dfdede;
      padding: 20px 15px 20px 15px;
      background: #fff;
      border-radius: 20px 20px 0px 0px;
      z-index: 9999;

      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0px;
        position: relative;
      }

      a {
        margin-left: 10px;

        :global(svg) {
          color: #50cb5d;
        }
      }
    }

    .cotentBody {
      background: #fff;
      border-radius: 0px 0px 10px 10px;
      text-align: center;
      overflow: hidden;
      padding: 20px 20px;

      h5 {
        font-size: 20px;
        padding: 25px 0px;
        margin-bottom: 0px;
      }

      .marginMin {
        margin-bottom: -1px;
      }

      .actionBtn {
        width: 50%;
        background: var(--Main);
        border-radius: 0px;
        color: #fff !important;
        padding: 10px 15px;
        border: none;
      }

      .homeworkHeading {
        background-color: var(--Main);
        padding: 14px;
        position: relative;

        h3 {
          margin-bottom: 0px;
          font-size: 22px;
          color: #fff;
        }

        .closeIcon {
          right: 15px;
          width: 20px;
          height: 20px;
          top: 17px;
          background: #fff;
          box-shadow: none;
        }
      }

      .homeworkDownload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
        border-bottom: 1px solid #ededed;

        h3 {
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 600;
        }

        svg {
          font-size: 24px;
          color: var(--Main) !important;
        }
      }

      .homeworkScroll {
        overflow: hidden;
        max-height: calc(50vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.bookBtn {
  background: var(--Main) !important;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;
}

.whatsAppBtn {
  background: #eeeede;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
  font-weight: 500;
  transition: .3s;

  &:hover {
    background-color: rgb(78, 202, 92) !important;

    a {
      color: #fff !important;
    }
  }

  a {
    padding: 0px 10px;
    color: rgb(78, 202, 92) !important;
    font-size: 30px;
    position: relative;
    top: -2px;
  }
}

.ratingStar {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    cursor: pointer;
    font-size: 30px;
    margin-right: 5px;
    color: var(--Main) !important;

    &.active {
      color: var(--Main);
    }
  }

}

.buttonTwo {
  border-radius: 10px 10px 10px 10px !important;
  width: 398px !important;

  .buttonTo {
    border-radius: 10px 10px 10px 10px !important;
    width: 100% !important;
    position: static !important;
    border-bottom: none !important;
  }

  .buttonTT {
    padding: 20px 15px 20px 15px !important;

    .fileBtn {
      position: relative;
      overflow: hidden;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

}

// .popupBtnTwo.popupShow {

//   .buttonTwo {
//     bottom: 90px;
//     display: block;
//     transform: translate(100%, -100%);
//     left: 30px;
//   }

// }

.popupBoxmodule {

  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
      height: 490px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: fixed;
    z-index: 9999;
    width: 398px !important;
    top: 50%;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;

    .cotentBody {
      background: #fff;
      border-radius: 10px;
      text-align: center;
      overflow: hidden;

      h5 {
        font-size: 20px;
        margin: 0;
        padding: 25px 0px;
      }

      .actionBtn {
        width: 50%;
        background: var(--Main);
        border-radius: 15px;
        color: #fff !important;
        padding: 10px 15px;
        border: none;
        margin-bottom: 10px;

        &:focus {
          box-shadow: none;
        }
      }

      .homeworkHeading {
        background-color: var(--Main);
        padding: 14px;
        position: relative;
        display: flex;
        justify-content: space-between;

        h3 {
          margin-bottom: 0px;
          font-size: 22px;
          color: #fff;
        }

        .closeIcon {
          right: 15px !important;
          width: 20px;
          height: 20px;
          top: 17px !important;
          background: #fff;
          box-shadow: none;
          border-radius: 50% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .homeworkDownload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
        border-bottom: 1px solid #ededed;

        .homework_Pdf {
          font-size: 19px;
          font-weight: 600;
        }

        .homework_date {
          font-size: 12px;
          color: #9d9d9d;
          text-align: left;
        }

        h3 {
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 600;
        }

        svg {
          font-size: 24px;
          color: var(--Main) !important;
        }
      }

      .homeworkScroll {
        overflow: hidden;
        max-height: calc(50vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.changes_tabBox {
  :global(.nav) {
    :global(.nav-item) {
      :global(.btn) {
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid transparent;

        &:global(.active) {
          border-bottom: 1px solid rgb(6, 6, 250);
        }
      }
    }
  }
}


.slider_mainSection {
  margin-top: 20px;
}

.swiperSelect_main {
  position: relative;

  .swiperSelect_details {
    position: relative;
    top: 58px;
    margin-bottom: 60px;

    :global(.swiper) {
      padding: 0px 6px;
    }
  }

  .swiperSelect_pillBox {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    :global(.swiper) {
      margin: 0px 6px;
      background-color: var(--Main3);

      :global(.swiper-wrapper) {
        :global(.swiper-slide) {
          .swiperSelect_pill_btn {
            text-align: center;
            cursor: pointer;
            color: #375dbc;
            font-size: 17px;
            padding: 13px 5px;
            border-bottom: 2px solid transparent;
          }

          &:global(.swiper-slide-thumb-active) {
            border-bottom: 2px solid #375dbc;
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .popupBoxmodule {

    .popupBoxBody {
      width: 100% !important;
    }
  }


  .popupBox {
    .bgBox {
      background: #00000070;
      position: fixed;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
    }

    &.popupShow {

      .popupBoxBody {
        bottom: 0;
        display: block;
        transform: translate(-0%, -50%);
        left: 0;
      }

      .bgBox {
        bottom: 0px;
      }
    }

    .popupBoxBody {
      position: fixed;
      z-index: 9999;
      width: 100%;
      bottom: -100%;
      background: #fff;
      overflow: hidden;
      height: 170px;
      border-radius: 20px 20px 0px 0px;
      transition: 0.5s;
      display: none;
      top: auto;


      &.popupBoxHeight {
        height: fit-content;
        // max-height: calc(100vh - 273px);
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 20px;
        top: 50% !important;
        width: 100% !important;
        ;

        .closeIcon {
          right: -8px !important;
        }

      }

      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }

      .closeIcon {
        position: absolute;
        right: -18px;
        z-index: 9999;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        box-shadow: 0 0px 4px 1px #ebebeb;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
      }

      .popupTitle {
        border-bottom: 1px solid #dfdede;
        padding: 20px 15px 20px 15px;
        background: #fff;
        width: 100%;
        border-radius: 20px 20px 0px 0px;


        h5 {
          text-align: center;
          font-size: 20px;
          margin: 0px;
          position: relative;
        }
      }


      .cotentBody {
        padding: 55px 20px 20px 20px;

        h6 {
          font-size: 20px;
          margin-bottom: 12px;
        }
      }
    }

    .popupFeedBack {
      transform: translate(0%, -16%) !important;
      height: 497px !important;
    }

  }

  .popupBtnTwo.popupShow {

    .buttonTwo {
      top: 50%;
      display: block;
      transform: translate(0%, -50%);
      left: 18px;
      width: 90% !important;
      height: 230px;
    }
  }

  .classCard {

    margin: 0px 10px !important;


    // :global(.btn) {
    //   margin-bottom: 0px;
    // }


  }
}