@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
      color: #000 !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

:global(.buttonWrapper) {
  display: none;

}

.contentBody {
  padding: 20px 20px;
}

.spaceBox {
  margin-top: 52px;
}

.classCard {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  margin: 10px 0px 20px 0px;

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;
    border: none;

    &:focus,
    &:hover,
    &.active {
      background-color: var(--Main2) !important;
      box-shadow: none;
      border: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 10px;

    li {
      display: flex;
      justify-content: space-between;

      span:first-child {
        padding-right: 15px;

        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .themeColor {
        color: #375DBC;
      }

      .greenColor {
        color: #00963C;
      }

      .redColor {
        color: #f00;
      }

      span {
        display: flex;
        align-items: center;

        p {
          margin: 0px;
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  .classForm {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .classDate {

      label {
        font-size: 18px;
        color: #2c2a2a;
        margin-bottom: 4px;
        font-weight: 500;
        display: block;
      }

      input {
        border-radius: 6px;
        border: 1px solid #E0E0E0;
        color: #6b6b6b;
        padding: 10px 6px;
      }
    }
  }

  .withdrowBox {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    input {
      width: 190px;
      padding: 8px;
      height: 45px;
      border-radius: 6px;
      background: #fff;
      border: 1px solid #fff;
      box-shadow: 0 0px 4px 1px #ebebeb;
      color: #242424;
      font-size: 17px;
      font-weight: 400;

    }

    a {
      height: 45px;
      background: var(--Main);
      padding: 22px;
      border-radius: 6px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      border: 1px solid #375dbc;
      color: #fff !important;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

}

.payoutCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 20px;

  h4 {
    font-size: 18px;
    color: #375DBC;
    font-weight: 600;
    margin: 0px;
  }

  input {
    width: 150px;
    text-align: center;
    height: 45px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0px 4px 1px #ebebeb;
    color: #375DBC;
    font-size: 20px;
    font-weight: 600;
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .classCard {
    .classForm {
      input {
        padding: 10px 4px !important;
        font-size: 15px;
      }
    }
  }
}