@import "../../../variables.module.scss";



.headerTitle {

    .sticky {
        position: sticky;
        top: 0;
        z-index: 99999;
        min-height: 60px;
        background: var(--Main);
        margin-bottom: -10px;
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
      .skip {
          color:#fff !important;
          text-decoration: none;
      }
  }

  .bgShape {
      width:100%;
      object-fit: cover;
  }
  .logo {
      height:60px;
  }
}

.contentBody {
  padding:20px 20px;

  h5 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 4px;
  }
}

:global(.react-tel-input  ){
    :global(.form-control ){
        border: 1px solid white !important;
        box-shadow: 0 0px 4px 1px #ebebeb !important;
        border-radius: 10px !important;
        padding: 12px 18px;
        width: 100% !important;
        margin-bottom: 20px !important;
        padding-left: 46px;
        height: 48px;
    }
}

.profileEdit{
    :global(.css-1s2u09g-control){
        border: 1px solid white;
        box-shadow: 0 0px 4px 1px #ebebeb;
        border-radius: 10px;
        padding: 6px 5px;

        &:hover{
            border-color: #fff !important; 
        }
    }
    :global(.css-1okebmr-indicatorSeparator){
        display: none !important;
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape{
        height: 110px;
    }
}
