@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.classList {
  display: flex;
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  text-decoration:none;
  color:#323030 !important;
  margin-top: 20px;


  .classImg {

    img {
      width: 85px;
      height: 85px;
      object-fit: cover;
      border-radius: 8px;
    }
    
    .applied {
      background: #32BF46;
      color: #fff;
      font-size: 12px;
      padding: 2px 15px;
      display: inline-block;
      border-radius: 5px;
      margin-top: 6px;
    }
  }
  .classListBody {
    margin-left:15px;
    
    h5 {
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 14px;
      margin-bottom: 0px;
      color: var(--Color);
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .arrow {
      font-size:20px;
    }
  }
}

.catHome {
  display: flex;

  :global(.item) {
    text-align: center;
  }
  .allData {
    margin-left:-5px;
  }
  .catLink {
    display: inline-block;
    text-align: center;
    color: #000 !important;
    text-decoration: none;
    width: 100%;
    margin-right: 15px;

    img {
      width: 60px !important;
      height: 60px;
      border-radius: 50px;
      margin:0 auto;
    }
    h6 {
      font-size:14px;
      margin:5px 0px 0px 0px;
      font-weight: normal;
    }
  }
}

.contentBody {
  padding:20px 20px;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
 
}
