@import "../../../variables.module.scss";

.socialSignup {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;


}

.headerTitle {

    .titleBar {
        margin: 0;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 15px 5px;
        align-items: center;

        .back {
            color: #fff !important;
            font-size: 20px;
        }

        .skip {
            color: #fff !important;
            text-decoration: none;
        }
    }

    .bgShape {
        width: 100%;
        object-fit: cover;
    }

    .logo {
        height: 60px;
    }
}

.contentBody {
    padding: 20px 20px;

    h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 4px;
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape {
        height: 110px;
    }
}