@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;
    background: #375DBC;
    color: #fff;

    .back {
      color: #fff !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.cardBody {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px 15px 15px !important;
  margin: 10px 0px 20px 0px;
  margin-top: 80px;

  .purchaseBox {
    .textRight {
      text-align: right;
    }

    input {
      width: 110px;
      height: 35px;
      background-color: #D4DFFA;
      border: 1px solid #375DBC;
      font-size: 13px;
      border-radius: 6px;
      color: #375DBC;
      font-weight: 600;
    }

    .purchaseBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;

      p {
        margin: 0;
        font-size: 15px;
        font-weight: 500;

        span {
          font-weight: 600;
          color: #375DBC;
        }
      }

      .purchaseNo {
        background-color: #375DBC;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  .tableBorder {
    margin-top: 14px;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 6px;

    .borderBottom {
      border-bottom: 1px solid #e2e2e2;
      padding: 10px;
    }

    .purchaseTable {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      p {
        font-size: 15px;
        font-weight: 500;
        margin: 0px;

        span {
          font-weight: 600;
          color: #375DBC;
        }
      }
    }

  }

  .proceedPay {
    width: 100%;
    background: var(--Main) !important;
    color: #fff !important;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    padding: 0px;
    margin-top: 16px;
  }
}

.referNow {

  // position: relative;
  .referLink {
    height: 44px;
    width: 100%;
    border-radius: 6px;
    border: none;
    background: #fff;
    padding: 0px 12px;
    color: #9A9A9A;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    font-weight: 500;
    font-size: 14px;
  }

  .referCopy {


    height: 42px;
    width: 110px;
    background: #375dbc;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    border: none;
    border-radius: 6px;
  }
}



.popupBox {
  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    width: 350px;

  }
}



.contentBody {
  padding: 20px 20px;
  width: 600px;
  margin: 0 auto;


}

.successPopup {
  background-color: #fff;
  border-radius: 10px;

  .successFull {
    background: #32BF46;
    text-align: center;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 0px;

    h5 {
      font-size: 24px;
      font-weight: 500;
    }

    svg {
      font-size: 60px;
      margin-top: 10px;
    }
  }

  h4 {
    font-size: 17px;
    padding-top: 12px;
    width: 60%;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
  }

  .continue {
    background-color: #32bf46;
    color: #fff !important;
    font-weight: 500;
    width: 100%;
    margin: 20px 0px;
  }
}

.failedPopup {
  background-color: #fff;
  border-radius: 10px;

  .successFull {
    background: #FF0000;
    text-align: center;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 0px;

    h5 {
      font-size: 24px;
      font-weight: 500;
    }

    svg {
      font-size: 60px;
      margin-top: 10px;
    }
  }

  h4 {
    font-size: 17px;
    padding-top: 12px;
    width: 60%;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
  }

  .continue {
    background-color: #FF0000;
    color: #fff !important;
    font-weight: 500;
    width: 100%;
    margin: 20px 0px;
  }
}

.whatsapp_add {
  text-align: center;
  margin-top: 6px;

  .add_number {
    font-size: 13px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover{
      text-decoration: none;
    }

    :global(svg){
      font-size: 20px;
      color: #4eca5c !important;
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .contentBody {
    width: 100% !important;
  }

  .referNow {
    // position: relative;

    .referCopy {
      height: 47px;
    }
  }
}

@media (max-width: 514px) {
  .referNow {
    .referCopy {
      width: fit-content;
    }
  }
}