@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
      color: #000 !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.spaceBox {
  margin-top: 50px;
}

.bookBtn {
  background: var(--Main) !important;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;
}

.weekList {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  background: #F2F6FF;
  flex-wrap: wrap;

  li {
    width: 14.28%;

    button {
      border: none;
      background: none;
      text-transform: uppercase;
      color: #000;
      padding: 15px 10px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      width: 100%;

      &.active {
        color: var(--Main);
      }
    }
  }
}

.forwardBackwardbtn {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  button {
    background-color: var(--Main) !important;
    color: #fff !important;
    border-radius: 10px;
    border: none !important;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
  }
}

.contentBody {
  padding: 20px 10px !important;

  .labelTitleRow {
    padding: 0px 5px;

    .labelTitle {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .addedFieldRows {
    margin-bottom: 10px;

    .addedField {
      padding: 0px 5px;

      :global(.btn) {
        background-color: var(--Main);
        color: #fff;
        width: 100%;
        height: 40px;
      }

      :global(.form-control) {
        height: 40px;
        border: 1px solid lightgrey;
        border-radius: 5px;

        &:focus {
          box-shadow: none;
        }
      }
    }


  }



}


.popupBox {
  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: absolute;
    z-index: 9999;
    width: 398px;
    top: 50%;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;

    .cotentBody {
      background: #fff;
      border-radius: 10px;
      text-align: center;
      overflow: hidden;

      h5 {
        font-size: 20px;
        margin: 0;
        padding: 25px 0px;
      }

      .actionBtn {
        width: 50%;
        background: var(--Main);
        border-radius: 0px;
        color: #fff !important;
        padding: 10px 15px;
        border: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.availabilityBtn {
  width: 410px !important;
}

.availabilityText {
  padding: 20px 0px;
  text-align: center;

  h6 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}

.confirmCheckbox {
  display: flex;
  gap: 10px;
  padding: 15px 0px;

  input {
    width: 18px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .availabilityBtn {
    width: 100% !important;
  }

  .availabilityText {
    display: none;
  }

  .contentBody {
    padding: 10px 20px !important;
    margin-top: 10px !important;
  }

  .confirmCheckbox {

    padding: 5px 0px !important;
    padding-bottom: 20px !important;

    input {
      width: 14px;
    }

    p {
      font-size: 13px !important;

    }
  }

}