@import "variables.module.scss";

body {
	background-color: #fff;
	font-family: 'Inter', sans-serif !important;
}


.scroll {
	overflow: hidden;
	height: calc(100vh - 88px);
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #00000030;
	}
}

.checkbox {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding-left: 25px;



	input {
		opacity: 0;
		margin-right: 10px;
		position: absolute;

		&:checked~.checkmark {
			border: 2px solid #B8C0CC;
		}

		&:checked~.checkmark:after {
			display: block;
		}
	}

	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #fff;
		border-radius: 3px;
		border: 2px solid #B8C0CC;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 5px;
			top: 1px;
			width: 5px;
			height: 10px;
			border: solid #B8C0CC;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

.radio {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover input~.checkmark {
		background-color: #f3f1f1;
		border: 1px solid #d8d8d8;
	}

	input {
		opacity: 0;
		margin-right: 5px;

		&:checked~.checkmark {
			background-color: var(--Main2);
			border: 1px solid var(--Main2);
		}

		&:checked~.checkmark:after {
			display: block;
		}
	}

	.checkmark {
		position: absolute;
		top: 5px;
		left: 0;
		height: 17px;
		width: 17px;
		background-color: #eee;
		border-radius: 50%;
		border: 1px solid #d8d8d8;

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 4px;
			top: 4px;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: white;
		}
	}
}

.themeColor {
	color: var(--Main);
}

.fontColor {
	color: var(--Color);
}

a {
	color: var(--Main) !important;
}

.spaceBox {
	margin-top: 60px;
	display: inline-block;
}

.formField {
	margin-bottom: 20px !important;

	&.leftIcon {

		.actionFirst {
			position: absolute;
			cursor: pointer;
			padding: 2px;
			font-size: 19px;
			left: 10px;
			top: 50%;
			transform: translate(-0%, -50%);
			color: var(--Color);
			margin-top: -1px;
			z-index: 9;
		}

		:global(.form-control) {
			padding-left: 40px;
		}

		:global(.form-select) {
			padding-left: 40px;
		}
	}

	:global(.form-control) {
		border: 1px solid white;
		box-shadow: 0 0px 4px 3px #ebebeb;
		border-radius: 10px;
		padding: 12px 18px;

		&:focus {
			box-shadow: 0 0px 4px 1px #ebebeb;
			border: 1px solid var(--Main);
		}
	}

	:global(.form-select) {
		border: 1px solid white;
		box-shadow: 0 0px 4px 1px #ebebeb;
		border-radius: 10px;
		padding: 12px 18px;

		&:focus {
			box-shadow: 0 0px 4px 1px #ebebeb;
			border: 1px solid var(--Main);
		}
	}

	.actionIcon {
		position: absolute;
		cursor: pointer;
		padding: 2px;
		font-size: 25px;
		right: 0;
		top: 50%;
		transform: translate(-50%, -50%);
		color: var(--Color);
	}

	.forgotPassword {
		color: var(--Color) !important;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: end;
		margin-top: 12px;

		.icon {
			margin-right: 5px;
			font-size: 14px;
		}
	}

	.actionBtn {
		width: 100%;
		background: var(--Main) !important;
		color: #fff !important;
		height: 50px;
		line-height: 50px;
		border-radius: 10px;
		padding: 0px;

		&:focus,
		&:hover {
			box-shadow: none;
			background-color: var(--Main2) !important;
			color: #fff !important;
		}
	}
}

.pageContent {
	display: flex;
	min-height: 648px;
	max-height: 100%;
	// min-height: 700px;

	.leftContent {
		// position: absolute;
		// left: 0;
		background-color: #fff;
		// max-width: 100%;
		margin: 0 auto;
		width: 100%;
		margin-top: 24px;
		// border-right: 2px solid #e3e3e3;

		&::-webkit-scrollbar {
			width: 0px;
		}

		&::-webkit-scrollbar-track {
			background-color: #f1f1f1;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #c1c1c1;
		}
	}

	.marginTopp {
		margin-top: 0px !important;
	}

}

.fixHeader {
	position: fixed !important;
	z-index: 99;
	background: #fff;
	width: 100% !important;
}

.width100 {
	width: 100%;
}

.formFull {
	margin: 0 auto;
}

.cursor {
	cursor: pointer;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

	:global(.container) {
		padding: 0px 0px !important;
	}

	.pageContent {
		display: flex;
		padding-bottom: 0px;
		min-height: auto;

		.leftContent {
			max-width: 100%;
			border-right: none;
		}

	}

	.fixHeader {
		width: 100% !important;
	}

	.leftContent {
		position: absolute;
		left: 0;
		background-color: #fff;
		max-width: 400px;
		width: 100%;
		overflow: hidden;
		height: calc(100vh - 0px);
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 0px !important;
	}

	.formFull {
		width: 100%;
	}

	.responsive_bottom_height {
		height: 76px;
	}


}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY END///////////////
=======================================================*/