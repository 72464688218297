.footerSection {
    background: #375DBC;
    padding: 34px 0px 30px;
    width: 100%;

    .footerStart {

        .titleBar {

            .footerLeft {

                .logo {

                    img {
                        width: 260px;
                        height: auto;
                        margin-bottom: 24px;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 0px;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 0px;

                    li {
                        padding: 0px 14px;
                        margin-bottom: 12px;

                        a {
                            color: #fff !important;
                            text-decoration: none;
                        }
                    }

                    span {
                        color: #fff;
                    }
                }
            }

        }
    }
}

.logoFixed {
    position: fixed;
    bottom: 14px;
    right: 42px;
    z-index: 999;

    a {
        font-size: 48px;
        color: rgb(78, 202, 92) !important;
    }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .footerSection {
        display: none !important;
    }

    .logoFixed {
        bottom: 72px;
        right: 12px
    }

}