@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;
    background: #375DBC;
    color: #fff;

    .back {
      color: #fff !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.spaceBox {
  margin-top: 50px;
}

.offerBox {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px !important;
  margin: 10px 0px 20px 0px;
}

.classCard {
  padding: 15px 0px;
  margin: 10px 0px 0px 0px;

  img {
    width: 100%;
    margin-bottom: 30px;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }

  .offerDemo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    h6 {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
    }

    p {
      margin: 0;
      color: #375DBC;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .offerText {
    color: #818181;
    font-size: 15px;
    line-height: 22px;
  }

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;

    &:focus {
      background-color: var(--Main2);
      box-shadow: none;
    }
  }

}

.menuList {
  padding: 0px 20px;

  :global(.nav) {

    :global(.nav-item) {
      width: 50%;
      padding: 0px 4px;

      :global(.nav-link) {
        background-color: #fff;
        padding: 7px 10px;
        width: 100%;
        display: block;
        border: none;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0 0px 4px 1px #e3e3e3;
        font-weight: 500;
        font-size: 18px;

        &:global(.active) {
          color: #fff !important;
          background: #375dbc;
        }
      }
    }
  }
}

.contentBody {
  padding: 20px 20px;
}

.availableClass {
  margin-top: 80px;
  text-align: center;

  p {
    font-size: 16px;
    color: #818181;
  }

  .classCount {
    background: #fff;
    border: none;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    height: 40px;
    width: 166px;
    border-radius: 6px;
    color: #375dbc;
    font-weight: 600;
    font-size: 15px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}

.recentTransactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  h5 {
    font-size: 19px;
    font-weight: 600;
    margin: 0;
  }

  input {
    height: 30px;
    width: 52px;
    text-align: center;
    background-color: #375DBC;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    border: none;

  }
}

.referalsHistory {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px 15px 15px !important;
  margin: 10px 0px 20px 0px;
  // border-left: 8px solid #b632ff;
  display: block;
  text-decoration: none;

  .referalsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #375DBC;
      padding: 0;
    }
  }

  .classTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    padding-top: 7px;

    span {
      font-weight: 600;
    }
  }

}

.enrollBtn {
  color: #fff !important;
  background: #375dbc;
  border-radius: 4px;
  padding: 6px;
  width: 9.5%;
  margin-top: 8px;
}

.popupBox {
  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: absolute;
    z-index: 9999;
    width: 650px !important;
    top: 50% !important;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }

    .closeIcon {
      position: absolute;
      right: 0px;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }

    .popupTitle {
      position: fixed;
      left: 0;
      border-bottom: 1px solid #dfdede;
      padding: 20px 15px 20px 15px;
      background: #fff;
      width: 398px;
      border-radius: 20px 20px 0px 0px;
      z-index: 9999;

      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0px;
        position: relative;
      }
    }


    .cotentBody {
      background: #fff;
      border-radius: 10px;
      text-align: center;
      overflow: hidden;

      h5 {
        font-size: 20px;
        padding: 25px 0px;
        margin-bottom: 0px;
      }

      .marginMin {
        margin-bottom: -1px;
      }

      .actionBtn {
        width: 50%;
        background: var(--Main);
        border-radius: 0px;
        color: #fff !important;
        padding: 10px 15px;
        border: none;
      }

      .homeworkHeading {
        background-color: var(--Main);
        padding: 14px;
        position: relative;

        h3 {
          margin-bottom: 0px;
          font-size: 22px;
          color: #fff;
        }

        .closeIcon {
          right: 15px;
          width: 20px;
          height: 20px;
          top: 17px;
          background: #fff;
          box-shadow: none;
        }
      }

      .homeworkDownload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
        border-bottom: 1px solid #ededed;

        h3 {
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 600;
        }

        svg {
          font-size: 24px;
          color: var(--Main) !important;
        }
      }

      .homeworkScroll {
        overflow: hidden;
        max-height: calc(50vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.taskForm {
  margin: 0px;
  padding: 20px 10px 10px 10px;

  :global(.form-select) {
    padding: 0px 15px;
    height: 50px;
    margin-bottom: 10px;
  }

  .submitBtn {
    padding: 0px 15px;
    height: 50px;
    background: var(--Main) !important;
    color: #fff !important;
    width: 100%;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {


  .taskForm {
    padding: 20px 10px 20px 10px;
  }


  .enrollBtn {
    width: 43%;
  }

  .popupBox {

    .bgBox {
      width: 100%;
    }

    .popupBoxBody {
      width: 100% !important;

      .popupTitle {
        width: 100%;
      }

    }
  }

  .referalsHistory {
    margin: 10px 15px;
  }

  .menuList {
    :global(.nav) {

      :global(.nav-item) {
        width: 45%;
        margin: 0px 8px;


      }
    }
  }
}