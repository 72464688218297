@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .gift {
      font-size: 25px;
      line-height: 0px;
    }

    .wallet {
      font-size: 23px;
      line-height: 0px;
      margin-left: 15px;
    }

    .notification {
      font-size: 27px;
      position: relative;

      span {
        position: absolute;
        font-size: 8px;
        right: -3px;
        top: 9px;
        border: 2px solid #ffffff;
        background: var(--Main);
        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 11px;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-weight: 500;
      }
    }

    .searchBox {
      position: relative;
      display: flex;
      margin: 0px 10px;
      width: 100%;

      .icon {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 20px;
        top: 50%;
        cursor: pointer;
        font-size: 20px;
        color: var(--Main);
      }

      :global(.form-control) {
        border: 1px solid #dfd9d9;
        box-shadow: none;
        border-radius: 10px;
        height: 40px;
        line-height: 40px;
        padding: 0px 15px 0px 35px;

        &:focus {
          box-shadow: none;
          border: 1px solid var(--Main);
        }
      }

      .searchListView {
        box-shadow: 0 0px 4px 1px #e3e3e3;
        background-color: #fff;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        top: 45px;
        width: 100%;

        ul {
          padding-left: 0px;

          li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0px;

            a {
              text-decoration: none;
            }
          }
        }
      }

    }
  }

}

.sliderHome {
  padding: 10px 0px 0px 0px;


}

.seeAllBtn {
  text-decoration: underline;
  color: #375DBC;
  font-weight: 600;
  font-size: 17px;
}

.catHome {
  margin-top: 20px;
  display: flex;

  :global(.item) {
    text-align: center;
  }

  .allData {
    margin-left: -5px;
  }

  .catLink {
    display: inline-block;
    text-align: center;
    color: #000 !important;
    text-decoration: none;
    width: 100%;
    margin-right: 15px;
    background: transparent;
    border: none;

    img {
      width: 60px !important;
      height: 60px;
      border-radius: 50px;
      margin: 0 auto;
    }

    h6 {
      font-size: 14px;
      margin: 5px 0px 0px 0px;
      font-weight: normal;
    }

    &:global(.active),
    &.active {
      img {
        border: 3px solid #375DBC;
      }
    }
  }
}

.classList {
  display: flex;
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  text-decoration: none;
  color: #323030 !important;
  margin-top: 30px;
  min-height: 130px;
  cursor: pointer;


  .classImg {

    img {
      width: 85px;
      height: 85px;
      object-fit: cover;
      border-radius: 8px;
    }

    .applied {
      background: rgb(225, 225, 0);
      color: #fff;
      font-size: 12px;
      padding: 2px 15px;
      display: inline-block;
      border-radius: 5px;
      margin-top: 6px;
      width: 100%;
      text-align: center;
    }

    .assign {
      background: #32BF46;
      color: #fff;
      font-size: 12px;
      padding: 2px 15px;
      display: inline-block;
      border-radius: 5px;
      margin-top: 6px;
      width: 100%;
      text-align: center;
    }

    .pending {
      background: rgb(225, 225, 0);
      font-size: 12px;
      color: #fff;
      padding: 2px 15px;
      display: inline-block;
      border-radius: 5px;
      margin-top: 6px;
      width: 100%;
      text-align: center;
    }
  }

  .classListBody {
    margin-left: 15px;

    h5 {
      font-size: 18px;
      font-weight: 600;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    p {
      font-size: 14px;
      margin-bottom: 0px;
      color: var(--Color);
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .arrow {
      font-size: 20px;
    }
  }
}

.bookBtn {
  background: var(--Main) !important;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;
}

.contentBody {
  padding: 20px 20px;


}

.menuList {
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;

  .homeTabbtn {
    background-color: #fff;
    padding: 15px 10px;
    display: block;
    border: none;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    font-weight: 500;
    font-size: 18px;
    margin: 0px 8px;
    color: #000 !important;
    text-decoration: none;
    width: 100%;
  }

  .homeTabdark {
    background-color: #375DBC !important;
    color: #fff !important;
  }
}

.slLink {
  img {
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.headerTitle {
  display: none;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .menuList {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .slLink {
    img {
      height: auto;
    }
  }

  .headerTitle {
    display: block !important;
  }

  .contentBody {
    padding: 0px 16px !important;
  }
}