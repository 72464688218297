@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .gift {
      font-size: 25px;
      line-height: 0px;
    }

    .wallet {
      font-size: 23px;
      line-height: 0px;
      margin-left: 15px;
    }

    .notification {
      font-size: 27px;
      position: relative;

      span {
        position: absolute;
        font-size: 8px;
        right: -3px;
        top: 9px;
        border: 2px solid #ffffff;
        background: var(--Main);
        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 11px;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-weight: 500;
      }
    }

    .searchBox {
      position: relative;
      display: flex;
      margin: 0px 10px 0px 0px;
      width: 100%;

      .icon {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 20px;
        top: 50%;
        cursor: pointer;
        font-size: 20px;
        color: var(--Main);
      }

      :global(.form-control) {
        border: 1px solid #dfd9d9;
        box-shadow: none;
        border-radius: 10px;
        height: 40px;
        line-height: 40px;
        padding: 0px 15px 0px 35px;

        &:focus {
          box-shadow: none;
          border: 1px solid var(--Main);
        }
      }

      .searchListView {
        box-shadow: 0 0px 4px 1px #e3e3e3;
        background-color: #fff;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        top: 45px;
        width: 100%;

        ul {
          padding-left: 0px;

          li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0px;

            a {
              text-decoration: none;

              svg {
                position: relative;
                top: 0px;
                right: 15px !important;
                left: 0;
              }
            }
          }
        }
      }

    }
  }

}

.actionIcon {
  color: var(--Main) !important;
}

.spaceBox {
  margin-top: 72px;
}

.classCard {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  margin: 10px 0px 20px 0px;
  cursor: pointer;

  a {
    color: white !important;
  }

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;
    border: none;

    &:focus,
    &:hover,
    &.active {
      background-color: var(--Main2) !important;
      box-shadow: none;
      border: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 10px;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 400;

      .profileName {
        display: flex;
        align-items: center;
        gap: 11px;

        .imgClass {
          width: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 50%;
        }

        .profileName_details {
          h5 {
            margin-bottom: 0;
            line-height: 20px;
            font-size: 19px;
          }

          :global(.btn) {
            font-size: 11px;
            width: auto;
            padding: 2px 6px;
            border-radius: 4px;
          }
        }
      }

      span:first-child {
        padding-right: 15px;
        // width: 50%;

        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          button {
            border: none;
            background: none;
            transition: .2s;
            padding: 0;

            &:hover {
              color: #375dbc;
            }
          }
        }
      }

      input {
        border: none;
        text-align: right;
        color: var(--Main) !important;
        max-width: 140px;
        padding-right: 30px;

        &:focus-visible {
          outline: 0.5px solid #375dbc;
          border-radius: 4px;
        }
      }

      svg {
        position: absolute;
        font-size: 22px;
        color: #fff;
        right: 6px;
        top: 2px;
      }
    }
  }
}

.menuList {
  background: var(--Main3);
  padding: 0px 20px;

  :global(.nav) {

    :global(.nav-item) {
      width: 33.33%;

      :global(.nav-link) {
        background-color: transparent;
        padding: 15px 10px;
        width: 100%;
        display: block;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0px;
        text-align: center;
        cursor: pointer;

        &:global(.active) {
          border-bottom: 2px solid var(--Main);
        }
      }
    }
  }
}

.contentBody {
  padding: 20px 20px;
}


.popupBox {

  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      display: block;
      height: 490px;
      overflow: auto;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    position: fixed;
    z-index: 9999;
    width: 398px !important;
    top: 50% !important;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }

    .closeIcon {
      position: absolute;
      right: 0px;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }

    .popupTitle {
      position: fixed;
      left: 0;
      border-bottom: 1px solid #dfdede;
      padding: 20px 15px 20px 15px;
      background: #fff;
      width: 398px;
      border-radius: 20px 20px 0px 0px;
      z-index: 9999;

      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0px;
        position: relative;
      }
    }


    .cotentBody {
      background: #fff;
      border-radius: 10px;
      text-align: center;
      overflow: hidden;

      h5 {
        font-size: 20px;
        padding: 25px 0px;
        margin-bottom: 0px;
      }

      .marginMin {
        margin-bottom: -1px;
      }

      .actionBtn {
        width: 50%;
        background: var(--Main);
        border-radius: 0px;
        color: #fff !important;
        padding: 10px 15px;
        border: none;
      }

      .homeworkHeading {
        background-color: var(--Main);
        padding: 14px;
        position: relative;

        h3 {
          margin-bottom: 0px;
          font-size: 22px;
          color: #fff;
        }

        .closeIcon {
          right: 15px;
          width: 20px;
          height: 20px;
          top: 17px;
          background: #fff;
          box-shadow: none;
        }
      }

      .homeworkDownload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
        border-bottom: 1px solid #ededed;

        h3 {
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 600;
        }

        svg {
          font-size: 24px;
          color: var(--Main) !important;
        }
      }

      .homeworkScroll {
        overflow: hidden;
        max-height: calc(50vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.bookBtn {
  background: var(--Main) !important;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;
}

.whatsAppBtn {
  background: #eeeede;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0px;
  font-weight: 500;
  transition: .3s;

  &:hover {
    background-color: rgb(78, 202, 92) !important;

    a {
      color: #fff !important;
    }
  }

  a {
    padding: 0px 10px;
    color: rgb(78, 202, 92) !important;
    font-size: 30px;
    position: relative;
    top: -2px;
  }
}

.ratingStar {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    cursor: pointer;
    font-size: 30px;
    margin-right: 5px;
    color: #D7D7D7;

    &.active {
      color: var(--Main);
    }
  }

}

.btnInput {
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.customerBox {
  .bgBox {
    background: #00000070;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  &.popupShow {

    .popupBoxBody {
      bottom: 0;
      display: block !important;
    }

    .bgBox {
      bottom: 0px;
    }
  }

  .popupBoxBody {
    background: #fff;
    overflow: hidden;
    max-height: calc(100vh - 150px);
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 20px 20px 0px 0px;
    transition: 0.5s;
    display: none;
    position: fixed;
    z-index: 9999;
    width: 398px !important;
    top: 50% !important;
    transition: 0.5s;
    display: none;
    transform: translate(-50%, -50%);
    padding: 0px 20px;
    left: 50%;
    border-radius: 22px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }

    .closeIcon {
      position: absolute;
      right: 0px;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      box-shadow: 0 0px 4px 1px #ebebeb;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
    }

    .popupTitle {
      position: fixed;
      left: 0;
      border-bottom: 1px solid #dfdede;
      padding: 20px 15px 20px 15px;
      background: #fff;
      width: 398px !important;
      border-radius: 20px 20px 0px 0px;
      z-index: 9999;

      h5 {
        text-align: center;
        font-size: 20px;
        margin: 0px;
        position: relative;
      }
    }


    .cotentBody {
      padding: 90px 20px 20px 20px;

      h6 {
        font-size: 20px;
        margin-bottom: 12px;
      }
    }
  }
}



.swiperSelect_main {
  position: relative;

  .swiperSelect_details {
    position: relative;
    top: 58px;
    margin-bottom: 60px;

    :global(.swiper) {
      padding: 0px 6px;
    }
  }

  .swiperSelect_pillBox {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    :global(.swiper) {
      margin: 0px 6px;
      background-color: var(--Main3);

      :global(.swiper-wrapper) {
        :global(.swiper-slide) {
          .swiperSelect_pill_btn {
            text-align: center;
            cursor: pointer;
            color: #375dbc;
            font-size: 17px;
            padding: 13px 5px;
            border-bottom: 2px solid transparent;
          }

          &:global(.swiper-slide-thumb-active) {
            border-bottom: 2px solid #375dbc;
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .classCard {
    margin: 10px 8px 20px 8px !important;


  }

  .popupBox {

    .bgBox {
      width: 100%;
    }

    .popupBoxBody {
      width: 100%;

      .popupTitle {
        width: 100%;
      }

    }

  }

  .bgShape {
    height: 110px;
    display: block !important;
  }

  .contentBody {

    .paddingLeft {
      margin-top: 0px !important;
    }
  }
}