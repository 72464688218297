@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}
:global(.buttonWrapper) {
  display:none;

}
.contentBody {
  padding: 20px 20px;

    .addButton{
        text-align: right;
        margin-bottom: 14px;
        margin-top: 20px;

          a{
              background-color: #375DBC;
              color: #fff !important;
              border: 1px solid #375DBC;
              border-radius: 6px;
              text-decoration: none;
              font-weight: 600;
              font-size: 18px;
              margin-top: 8px;
              padding: 9px 40px;
          }
    }
}

.classCard {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  margin:10px 0px 20px 0px;

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;
    border:none;

    &:focus, &:hover, &.active {
    background-color: var(--Main2) !important;
    box-shadow: none;
    border:none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;    
    margin-bottom: 0px;

    li {
      display:flex;
      justify-content: space-between;

      span{
        font-size: 16px;
        font-weight: 600;
        margin: 8px 0px;

      }
      .themeColor{
        color: #375DBC;
      }
    }
  }
  a{
    background-color: #375DBC;
    color: #fff !important;
    border: 1px solid #375DBC;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    margin-top: 8px;
  }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
 
}
