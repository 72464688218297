@import "../../../variables.module.scss";



.headerTitle {

    .sticky {
        position: sticky;
        top: 0;
        z-index: 99999;
        min-height: 60px;
        background: var(--Main);
        margin-bottom: -10px;
    }

    .titleBar {
        margin: 0;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 15px 5px;
        align-items: center;

        .back {
            color: #fff !important;
            font-size: 20px;
        }

        .skip {
            color: #fff !important;
            text-decoration: none;
        }
    }

    .bgShape {
        width: 100%;
    }

    .logo {
        height: 60px;
    }
}

.contentBody {
    padding: 20px 20px;

    h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 4px;
    }
}

.formField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .grayBtn {
        text-align: center;
        border-radius: 8px;
        background: #9A9A9A !important;
        border: 1px solid #9A9A9A !important;
        color: #fff !important;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        padding: 8px 26px;
        width: 160px;
    }

    .actionBtn {
        text-align: center;
        border-radius: 8px;
        background: #375DBC !important;
        border: 1px solid #375DBC !important;
        color: #fff !important;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        padding: 8px 26px;
        width: 160px;
    }

}


:global(.css-13cymwt-control) {
    border: 1px solid #fff !important;
    box-shadow: 0 0px 4px 1px #ebebeb;
    border-radius: 10px !important;
    padding: 6px 5px;

    span {
        display: none !important;
    }
}

:global(.css-1s2u09g-control) {
    border: 1px solid #fff !important;
    box-shadow: 0 0px 4px 1px #ebebeb;
    border-radius: 10px !important;
    padding: 6px 5px;
}

:global(.css-b62m3t-container) {
    &:focus-visible {
        outline: none !important;
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {}