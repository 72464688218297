@import "../../../variables.module.scss";



.headerTitle {

    .sticky {
        position: sticky;
        top: 0;
        z-index: 99999;
        min-height: 60px;
        background: var(--Main);
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
      .skip {
          color:#fff !important;
          text-decoration: none;
      }
  }

  .bgShape {
      width:100%;
      object-fit: cover;
  }
  .logo {
      height:60px;
  }
}

.contentBody {
  padding:20px 20px;

  h5 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 4px;
  }
}

.formField{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .grayBtn{
        text-align: center;
        border-radius: 8px;
        background: #9A9A9A !important;
        border: 1px solid #9A9A9A !important;
        color: #fff !important;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        padding: 8px 26px;
        width: 160px;
    }

    .actionBtn{
        text-align: center;
        border-radius: 8px;
        background: #375DBC !important;
        border: 1px solid #375DBC !important;
        color: #fff !important;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        padding: 8px 26px;
        width: 160px;
    }
}
.submitBtn{
    text-align: center;
    border-radius: 8px;
    background: #375DBC !important;
    border: 1px solid #375DBC !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 26px;
    width: 100%;
    display: block;
    margin-top: 32px;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape{
        height: 110px;
    }
}   
