@import "../../../variables.module.scss";



.headerTitle {

    .sticky {

        .aboutUs{
            text-align: center;

            h6{
                margin: 0px;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

  }

  .bgShape {
      width: 100%;
      display: none;
  }
}


.contentBody {
    .paddingLeft{
        padding: 20px 20px;
    } 
    h5 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 4px;
    }

.profileText {
    display: flex;
    align-items: center;

    .userView{
        position: relative;
        .userImg{
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
        .editIcon {
            position: absolute;      
            bottom: 18px;
            right: -8px;
            overflow: hidden;
            cursor:pointer;

            input[type="file"] {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        
    }
    .userText{
        padding-left: 20px;

        p{
            margin-bottom: 0px;
            margin-top: 8px;
            color: #7F7F7F;
        }
    }
}
    
  
  .nextPage{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color:#000 !important;
    padding:20px 20px;
    border-bottom: 1px solid #e9e8e8;

    h6{
        font-size: 15px;
        margin: 0px;
    }
  }

}

.popupBox {
    .bgBox {
      background: #00000070;
      position: fixed;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
    }
  
    &.popupShow {
  
      .popupBoxBody {
        display:block;
      }
      .bgBox {
        bottom: 0px;
      }
    }
    .popupBoxBody {
      position: absolute;
      z-index: 9999;
      width: 398px;
      top: 50%;
      transition: 0.5s;
      display:none;
      transform: translate(-50%, -50%);
      padding: 0px 20px;
      left: 50%;
  
      .cotentBody {
        background: #fff;
        border-radius: 10px;
        text-align: center;
        overflow: hidden;
  
        h5 {
            font-size: 20px;
            margin: 0;
            padding: 25px 0px;
        }

        .actionBtn {
            width: 50%;
            background:var(--Main);
            border-radius: 0px;
            color:#fff !important;
            padding: 10px 15px;
            border:none;

            &:focus {
                box-shadow:none;
            }
        }
      }
    }
  }

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .popupBox {
    
        .bgBox {
          width: 100%;
        }
        .popupBoxBody {
          width: 100%;
    
          .popupTitle {
            width: 100%;
          }
        
        }
      }

      .bgShape{
        display: block !important;
      }
}
