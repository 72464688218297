@import "../../../variables.module.scss";



.headerTitle {

    .sticky {

        .aboutUs{
            text-align: center;

            h6{
                margin: 0px;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
      .skip {
          color:#fff !important;
          text-decoration: none;
      }
  }

  .bgShape {
      width:100%;
      object-fit: cover;
      display: none;
  }
  .logo {
      height:60px;
  }
}


.contentBody {
    margin-top: 104px !important;
    padding-bottom: 50px;

  h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape{
        height: 110px;
        display: block !important;
    }
    .contentBody {
        margin-top: 0px !important;
        padding-bottom: 0px !important;
        padding: 0px 20px !important;
    }
}   
