@import "../../../variables.module.scss";

.headerTitle {
  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;
    background: #375dbc;
    color: #fff;

    .back {
      color: #fff !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.spaceBox {
  margin-top: 50px;
}

.offerBox {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px !important;
  margin: 10px 0px 20px 0px;
}

.classCard {
  padding: 15px 0px;
  margin: 10px 0px 0px 0px;
  text-align: center;
  margin: 0px 7px;

  img {
    width: 20%;
    margin-bottom: 30px;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }

  .offerDemo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    h6 {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
    }

    p {
      margin: 0;
      color: #375dbc;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .offerText {
    color: #818181;
    font-size: 15px;
    line-height: 22px;
  }

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;

    &:focus {
      background-color: var(--Main2);
      box-shadow: none;
    }
  }
}

.menuList {
  padding: 0px 20px;
  margin-top: 80px;

  :global(.nav) {
    margin: 0px auto;
    display: flex;
    justify-content: center;

    :global(.nav-item) {
      width: 49%;
      margin: 0px 6px;

      :global(.nav-link) {
        background-color: #fff;
        padding: 15px 10px;
        width: 100%;
        display: block;
        border: none;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0 0px 4px 1px #e3e3e3;
        font-weight: 500;
        font-size: 18px;

        &:global(.active) {
          color: #fff !important;
          background: #375dbc;
        }
      }
    }
  }
}

.contentBody {
  padding: 20px 20px;
}

.referBtn {
  .referInput {
    margin: 0px 10px;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    background: #fff;
    border: none;
    border-radius: 6px;
    width: 140px;
    height: 44px;
    font-size: 18px;
    font-weight: 600;
  }

  .referCopy {
    border-radius: 6px;
    background-color: #375dbc;
    width: 140px;
    height: 44px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
}

.referNow {
  position: relative;

  .referLink {
    height: 44px;
    width: 90%;
    border-radius: 6px;
    border: none;
    background: #fff;
    padding: 0px 40px;
    color: #9a9a9a;
    box-shadow: 0 0px 4px 1px #e3e3e3;
    font-weight: 500;
    font-size: 14px;
  }

  svg {
    position: absolute;
    left: 18px;
    font-size: 16px;
    top: 14px;
    transform: translate(-50%, -0%);
    color: #9a9a9a;
  }

  .referCopy {
    position: absolute;
    right: 0px;
    height: 44px;
    width: 110px;
    background: #375dbc;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    border: none;
    border-radius: 6px;
  }
}

.referalsHistory {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px 15px 20px !important;
  margin: 10px 0px 20px 0px;
  border-left: 8px solid #b632ff;

  .referalsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;

    h6 {
      margin: 0;
      font-size: 17px;
      font-weight: 500;
    }

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #375dbc;
    }
  }

  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #818181;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .popupBox {
    .bgBox {
      width: 100%;
    }

    .popupBoxBody {
      width: 100%;

      .popupTitle {
        width: 100%;
      }
    }
  }

  .menuList {
    :global(.nav) {
      :global(.nav-item) {
        width: 45% !important;
        margin: 0px 6px;
      }
    }
  }

  .referNow {
    position: relative;
    margin: 0px 10px;

    .referLink {
      width: 68%;
    }

    .referCopy {
      width: 100px;
    }
  }

  .classCard {

    margin: 20px 11px 0px 11px;



  }
}