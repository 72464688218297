.student_detailsPopup{
    .imgbox{
        .imgClass{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    label{
        font-weight: 600;
        margin-bottom: 2px;
    }
    :global(.form-control){
        background-color: #f3f3f3;
        border: 1px solid #c7c7c7;
        line-height: 1.7;
    }
}