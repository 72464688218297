.container {
    display: flex;
    width: 100%;
    background: #F2F6FF;
    padding: 10px 0px;
}

.buttonWrapper {
    display: flex;
    align-items: flex-end;
    z-index: 2;
    background: inherit;
}

.button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    width: 30px;
    height: 40px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
}

.dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.monthContainer {
    display: flex;
    flex-direction: column;
}

.monthYearLabel {
    z-index: 3;
    margin-bottom:10px;
}

.dateDayItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin:0px 5px;
    flex-shrink: 0;
}

.daysContainer {
    display: flex;
    z-index: 1;
}

.dayLabel {
    font-size: 12px;
    margin: 0px 0 5px 0;
    font-weight: 500;
}

.dateLabel {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #e8e6e6;
    text-align: center;
    border-radius: 50px;
}