@import "../../variables.module.scss";

.splesHight{
    height: 100%;
}

.splashBox {

    .logo {
        height: 70px;
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%);
    }
}
.splashBoxBtns {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 85%;
    transform: translate(-50%, -50%);

    :global(.btn) {
        width: 220px;
        margin: 10px 10px;
        padding: 15px 15px;
       border: 1px solid var(--Main) !important;
       color:var(--Main) !important;

        &:focus {
            border-color:var(--Main);
        }

        &.studentBtn {
            background:var(--Main);
            color:#fff !important;
        }
    }

}
.contentBody {
  padding:20px 20px;
  text-align:center;

 
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .splashBoxBtns{

        :global(.btn) {
            width: 100%;
            margin: 10px 0px;
            display: block;
        }
    }
}
