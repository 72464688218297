@import "../../variables.module.scss";

.headerTitle {
  // margin-top: 40px;

  .titleBar {
    margin: 0;
    position: absolute;
    top: 73px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;
    background: var(--Main);
    color: #fff;

    .back {
      color: #fff !important;
      font-size: 20px;
    }

    .headTitle {
      text-align: center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
.chatting_TitleMain {
  display: none ;
}
.chatListSection {
  padding-top:100px;

  .chat_TabBox {
  
    .chat_person_head {

      background: #375dbc;

      .chat_person {
        padding: 10px;
        padding-left: 20px;
        border-right: 1px solid #fff;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .chat_showperson {
        display: flex;
        align-items: center;
        padding: 10px;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }

        .chat_showContent {
          margin-left: 13px;

          h5 {
            margin: 0;
            font-size: 20px;
            line-height: 18px;
            color: #fff;
          }
        }
      }
    }

    .scrolling_chat {
      height:calc(100vh - 110px);
      overflow: auto;

      .chatting_TitleMain {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }

    }

    :global(.nav-pills) {

      background-color: #eaf5fe;

      :global(.nav-item) {
        :global(.nav-link) {
          padding: 0;

          .chat_Avatar {
            display: flex;
            flex-direction: row;
            padding: 17px;
            border-bottom: 1px solid #d9d5d5;

            .chatAvatar_Main {
              margin-right: 10px;

              img {
                width: 44px;
                height: 44px;
                border-radius: 50%;
              }
            }

            .users_list_body {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex: 1;

              .chat_msg {
                h6 {
                  margin: 0;
                  color: #000;
                }

                p {
                  margin: 0;
                  font-size: .775rem;
                  color: #2a2828;
                }
              }

              .chat_time {
                color: #8796af;
                font-size: .725rem;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                .count {
                  -webkit-border-radius: 50px;
                  -moz-border-radius: 50px;
                  border-radius: 50px;
                  width: 20px;
                  height: 20px;
                  background: #375dbc;
                  color: #ffffff;
                  font-size: .7rem;
                  font-weight: 700;
                  margin: 0 0 0.2rem 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .time {
                  color: #101010;
                }

              }
            }
          }
        }

        :global(.active) {
          padding: 0;
          border-radius: 6px 6px 0px 0px;
          border-radius: 0;
          background-color: #7c9fde;
        }
      }
    }

    :global(.tab-content) {

      .chatting_contain {

        .chatting_backArrowMain {
          display: none;
        }
        .chatting_messagesContainer {
          position: relative;
          height: calc(100vh - 239px);
          overflow: auto;
  
          .chatting_backArrowMain {
            display: none;
          }
  
          &::-webkit-scrollbar {
            width: 0px;
          }
  
          &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
          }
  
          &:-webkit-scrollbar {
            display: none;
          }
          .left {
            display: block;
            text-align: left;
            margin-bottom: 10px;
      
            .bgChat {
              background: #eaeaea;
              display: inline-block;
              position: relative;
              padding: 5px 10px 15px 10px;
              border-radius: 5px 5px 5px 0px;
              min-width: 100px;
      
              p {
                margin: 0;
                font-size: 15px;
              }
      
              time {
                font-size: 10px;
                position: absolute;
                right: 10px;
                bottom: 2px;
                color: #9a9a9a;
              }
            }
          }
      
          .date {
            border-top: 1px solid #dbdbdb;
            text-align: center;
            position: relative;
            margin: 20px 0px 20px 0px;
            display: none;
      
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
              font-size: 14px;
              padding: 0px 10px;
              color: #727272;
            }
          }
      
          .right {
            display: block;
            text-align: right;
            margin: 6px 0px;
      
            .bgChat {
              background: var(--Main);
              display: inline-block;
              position: relative;
              padding: 5px 10px 15px 10px;
              border-radius: 5px 5px 0px 5px;
              min-width: 100px;
              color: #fff;
      
              p {
                margin: 0;
                font-size: 15px;
              }
      
              time {
                font-size: 10px;
                position: absolute;
                right: 10px;
                bottom: 2px;
                color: #fff;
              }
            }
          }
      
          .avatar_box {
            text-align: end;
            // margin-bottom: 10px;
      
            img {
              width: 150px;
              border-radius: 5px 5px 0px 5px;
            }
          }
        }
      }

      .chatForm {
        display: flex;
        width: 100%;
        padding: 15px 15px;
        border-top: 1px solid #dbdada;
        background: #fff;

        .chatType {
          width: 100%;
          position: relative;

          :global(.form-control) {
            padding: 10px 80px 10px 15px;
            border: 1px solid #bcbcbc;

            &:focus {
              box-shadow: none;
            }
          }

          .actionIcons {
            display: flex;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(-0%, -50%);
            align-items: center;

            .attachement {
              position: relative;
              font-size: 25px;
              color: #747171;
              cursor: pointer;
              padding: 3px;
              margin-left: 5px;
              overflow: hidden;

              input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
            }

            .camera {
              position: relative;
              font-size: 25px;
              color: #747171;
              cursor: pointer;
              padding: 3px;
              margin-left: 5px;
              overflow: hidden;

              input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
            }
          }
        }

        :global(.btn) {
          min-width: 47px;
          min-height: 47px;
          padding: 0px;
          background: var(--Main);
          border-radius: 50%;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-left: 10px;
          position: relative;

          &:hover {
            background: var(--Main);
            color: #fff;
          }

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
          }

          svg {
            color: #fff;

            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}




@media (max-width: 991px) {
  .chatting_TitleMain {
    display: block !important;
    background: #375dbc;
    color: #fff;
    padding: 15px 20px;
    font-size: 18px;
    text-align: center;
  }
  .chatListSection {
    padding-top:0px;
  
    .chat_TabBox {
    
      .mobileRow {
        margin: 0px;
      }
      .chat_person_head {
        display: none;
  
        .chat_person {
          padding: 10px;
          padding-left: 20px;
          border-right: 1px solid #fff;
  
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
  
        .chat_showperson {
          display: flex;
          align-items: center;
          padding: 10px;
  
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
  
          .chat_showContent {
            margin-left: 13px;
  
            h5 {
              margin: 0;
              font-size: 20px;
              line-height: 18px;
              color: #fff;
            }
          }
        }
      }
  
      .scrolling_chat {
        height:calc(100vh - 60px);
        overflow: auto;
  
        &.chatting_Scrolling_MobileHide {
          display: none;
        }
        .chatting_TitleMain {
          display: none;
        }
  
        &::-webkit-scrollbar {
          width: 4px;
        }
  
        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
        }
  
      }
  
      :global(.nav-pills) {
  
        background-color: #eaf5fe;
  
        :global(.nav-item) {
          :global(.nav-link) {
            padding: 0;
  
            .chat_Avatar {
              display: flex;
              flex-direction: row;
              padding: 17px;
              border-bottom: 1px solid #d9d5d5;
  
              .chatAvatar_Main {
                margin-right: 10px;
  
                img {
                  width: 44px;
                  height: 44px;
                  border-radius: 50%;
                }
              }
  
              .users_list_body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
  
                .chat_msg {
                  h6 {
                    margin: 0;
                    color: #000;
                  }
  
                  p {
                    margin: 0;
                    font-size: .775rem;
                    color: #2a2828;
                  }
                }
  
                .chat_time {
                  color: #8796af;
                  font-size: .725rem;
                  display: flex;
                  align-items: flex-end;
                  flex-direction: column;
  
                  .count {
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    border-radius: 50px;
                    width: 20px;
                    height: 20px;
                    background: #375dbc;
                    color: #ffffff;
                    font-size: .7rem;
                    font-weight: 700;
                    margin: 0 0 0.2rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
  
                  .time {
                    color: #101010;
                  }
  
                }
              }
            }
          }
  
          :global(.active) {
            padding: 0;
            border-radius: 6px 6px 0px 0px;
            border-radius: 0;
            background-color: #7c9fde;
          }
        }
      }
      .col8L {
        padding: 0px;
      }
      .col4L {
        padding: 0px;
      }
      :global(.tab-content) {
  
        .chatting_contain {
  
          .chatting_backArrowMain {
            display: flex;
            background: #375dbc;
            color: #fff;
            padding: 20px 20px;
            font-size: 18px;

            a {
              color: #fff !important;
            }
          }
          .chatting_messagesContainer {
            position: relative;
            height: calc(100vh - 148px);
            overflow: auto;
            padding: 10px 10px;
    
    
            &::-webkit-scrollbar {
              width: 0px;
            }
    
            &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }
    
            &::-webkit-scrollbar-thumb {
              background-color: #c1c1c1;
            }
    
            &:-webkit-scrollbar {
              display: none;
            }
          }
        }
  
        .chatForm {
          display: flex;
          width: 100%;
          padding: 15px 15px;
          border-top: 1px solid #dbdada;
          background: #fff;
  
          .chatType {
            width: 100%;
            position: relative;
  
            :global(.form-control) {
              padding: 10px 80px 10px 15px;
              border: 1px solid #bcbcbc;
  
              &:focus {
                box-shadow: none;
              }
            }
  
            .actionIcons {
              display: flex;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translate(-0%, -50%);
              align-items: center;
  
              .attachement {
                position: relative;
                font-size: 25px;
                color: #747171;
                cursor: pointer;
                padding: 3px;
                margin-left: 5px;
                overflow: hidden;
  
                input {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  cursor: pointer;
                }
              }
  
              .camera {
                position: relative;
                font-size: 25px;
                color: #747171;
                cursor: pointer;
                padding: 3px;
                margin-left: 5px;
                overflow: hidden;
  
                input {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  cursor: pointer;
                }
              }
            }
          }
  
          :global(.btn) {
            min-width: 47px;
            min-height: 47px;
            padding: 0px;
            background: var(--Main);
            border-radius: 50%;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            margin-left: 10px;
            position: relative;
  
            &:hover {
              background: var(--Main);
              color: #fff;
            }
  
            input {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
  
            svg {
              color: #fff;
  
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
 
}

@media only screen and (min-width: 577px) and (max-width: 767px) {}

@media only screen and (min-width: 767px) and (max-width: 991px) {}