@import "../../../variables.module.scss";

.mainBox{
  margin-top: 76px;

  .headerTitle{
    h1{
      text-align: center;
      font-size: 28px;
      margin-bottom: 18px;
    }
  }
}

.classCard{
  text-decoration: none;
  color: #000 !important;
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  margin: 10px 0px 16px 0px;
  display: block;

  ul{
    padding: 0;
    margin: 0;
    list-style: none;

    li{
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &:last-child{
        margin-bottom: 0px;
      }

      h5{
        margin-bottom: 0px;
      }

      span{
        font-size: 14px;
      }
    }
  }

}
/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  
}