.student_detailsPopup {
    .imgbox {
        .imgClass {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    label {
        font-weight: 600;
        margin-bottom: 2px;
    }

    :global(.form-control) {
        background-color: #f3f3f3;
        border: 1px solid #c7c7c7;
        line-height: 1.7;
    }
}

.modalteacherRating {
    .teacherRatingPopup {
        .rating_star {
            display: flex;
            justify-content: center;
            gap: 6px;
            margin-bottom: 18px;

            :global(svg) {
                font-size: 33px;
                color: #375dba;
            }
        }

        .feedback_content {
            border: 1px solid #d3d0d0;
            border-radius: 10px;
            padding: 10px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;

            p {
                margin-bottom: 8px;
                font-size: 15px;
            }
        }
    }
}

.reschedule_popUp {
    :global(.modal-content) {
        overflow: hidden;
        border-radius: 11px;
    }

    :global(.modal-header) {
        text-align: center;
        font-size: 20px;
        margin-bottom: 0;
        padding: 25px 11px;
        color: #000;
    }

    :global(.modal-footer) {
        border: none;
        flex-wrap: inherit;
        padding: 0;

        .actionBtn {
            background: var(--Main);
            border: none;
            border-radius: 0;
            color: #fff !important;
            padding: 10px 15px;
            width: 50%;
            margin: 0;
        }
    }
}

.customerSupport_popUp {
    :global(.modal-content) {
        overflow: hidden;
        border-radius: 22px;
        border: none;

        :global(.modal-header) {
            :global(.modal-title) {
                width: 100%;
                text-align: center;
            }

            :global(.btn-close) {
                margin: 0;
                border: 1px solid #fff;
                padding: 7px;
                box-shadow: 0 0 4px 1px #cbcbcb;
                border-radius: 50%;
                font-size: 13px;
            }
        }

        :global(.modal-body) {
            padding: 25px 31px;

            :global(textarea) {
                &:global(.form-control) {
                    border: 1px solid #fff;
                    border-radius: 10px;
                    box-shadow: 0 0 4px 3px #ebebeb;
                    padding: 12px 18px;
                }
            }

            .book_btn {
                background: var(--Main);
                border-radius: 8px;
                color: #fff !important;
                display: block;
                height: 50px;
                line-height: 50px;
                padding: 0;
                text-align: center;
                width: 100%;
                font-size: 18px;
                margin-top: 18px;
            }

            .whatsapp_btn {
                align-items: center;
                background: #eeeede;
                border-radius: 10px;
                display: flex;
                font-weight: 500;
                height: 50px;
                line-height: 50px;
                justify-content: center;
                margin-top: 12px;
                padding: 2px 0;
                transition: .3s;
                font-size: 18px;

                :global(svg) {
                    color: #4eca5c;
                    font-size: 40px;
                    padding-left: 10px;
                    transition: .2s;
                }

                &:hover{
                    background-color: #4eca5c;
                    color: #fff !important;
                    :global(svg){
                        color: #fff;
                    }
                }
            }
        }
    }

}


@media (min-width: 576px) {
    .modalteacherRating {
        :global(.modal-dialog) {
            max-width: 350px;
        }

        :global(.modal-content) {
            :global(.modal-title) {
                font-size: 20px;
            }
        }
    }

    .reschedule_popUp {
        :global(.modal-dialog) {
            max-width: 350px;
        }
    }
    .customerSupport_popUp {
        :global(.modal-dialog) {
            max-width: 400px;
        }
    }

}