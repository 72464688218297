@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}
:global(.buttonWrapper) {
  display:none;

}
.contentBody {
  padding: 20px 20px;
}
.spaceBox {
  margin-top:52px;
}

.classCard {
  box-shadow: 0 0px 4px 1px #e3e3e3;
  border-radius: 8px;
  padding: 15px 15px;
  margin:10px 0px 20px 0px;

  :global(.btn) {
    background-color: var(--Main);
    color: #fff !important;
    width: 100%;
    border-radius: 8px;
    border:none;

    &:focus, &:hover, &.active {
    background-color: var(--Main2) !important;
    box-shadow: none;
    border:none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;    
    margin-bottom: 10px;

    li {
      display:flex;
      justify-content: space-between;

      span:first-child{
        padding-right: 15px;
        width: 50%;

        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .themeColor{
        color: #375DBC;
      }
    }
  }
}


.amountRecieved{
    text-align: center;
    margin-top: 80px;
  h5{
    font-size: 18px;
    color: #375DBC;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .amountBox{

    input{
      width: 150px;
      text-align: center;
      height: 40px;
      border-radius: 8px;
      background: #C0C0C0;
      border: 1px solid #C0C0C0;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin: 0px 12px;
      padding: 8px 26px;
    }

    a{
      text-align: center;
      border-radius: 8px;
      background: #375DBC;
      border: 1px solid #375DBC;
      color: #fff !important;
      font-size: 18px;
      font-weight: 600;
      margin: 0px 12px;
      text-decoration: none;
      padding: 8px 26px;
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .contentBody {
    padding: 0px 10px !important;
    margin-top: 8px;
  }
}
