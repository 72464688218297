@import "../../../variables.module.scss";



.headerTitle {

    .sticky {
        position: sticky;
        top: 0;
        z-index: 99999;
        min-height: 60px;
        background: var(--Main);
    }

    .titleBar {
        margin: 0;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 15px 5px;
        align-items: center;

        .back {
            color: #fff !important;
            font-size: 20px;
        }

        .skip {
            color: #fff !important;
            text-decoration: none;
        }
    }

    .bgShape {
        width: 100%;
        object-fit: cover;
    }

    .logo {
        height: 60px;
    }

    :global(.react-tel-input) {
        input {
            padding-left: 48px !important;
            height: auto !important;
        }
    }
}

.profileEdit{
    :global(.css-b62m3t-container){
        border: 1px solid white;
        box-shadow: 0 0px 4px 1px #ebebeb;
        border-radius: 10px;
        padding: 6px 4px;
    }
    :global(.css-1s2u09g-control){
            border: none !important;
        }
}

.contentBody {
    padding: 20px 20px;

    h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 4px;
    }

    :global(.react-tel-input) {
        input {
            padding-left: 48px !important;
            height: auto !important;
            border: 1px solid white;
            box-shadow: 0 0px 4px 1px #ebebeb;
            border-radius: 10px;
            padding: 12px 18px;
            width: 100%;
            margin-bottom: 22px !important;
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape {
        height: 110px;
    }
}