@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.spaceBox {
  margin-top:50px;
}
.classesBox{
    padding: 0px 10px !important; 

    h3{
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .classCard {
      box-shadow: 0 0px 4px 1px #e3e3e3;
      border-radius: 8px;
      padding: 15px 15px;
      margin:10px 0px 20px 0px;

      :global(.btn) {
        background-color: var(--Main);
        color: #fff !important;
        width: 100%;
        border-radius: 8px;
        border:none;

        &:focus, &:hover, &.active {
        background-color: var(--Main2) !important;
        box-shadow: none;
        border:none;
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 15px;
        line-height: 28px;    
        margin-bottom: 10px;
    
        li {
          display:flex;
          justify-content: space-between;
          color: #9A9A9A;
          margin-bottom: 10px;
    
          span:first-child{
            padding-right: 15px;
            // width: 50%;
          }
          
          .themeColor {
            color: var(--Main);
            font-weight: 600;
          }
        }
      }
    }
}

.classesMargin{
  margin-top: 70px;
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  
}