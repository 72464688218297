@import "../../../variables.module.scss";



.headerTitle {
    margin-top: 80px;

    .sticky {

        .aboutUs{
            text-align: center;

            h6{
                margin: 0px;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

  .titleBar {
      margin: 0;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 15px 5px;
      align-items: center;

      .back {
          color:#fff !important;
          font-size: 20px;
      }
  }

  .bgShape {
      width:100%;
      object-fit: cover;
      display: none;
  }
}


.contentBody {
  padding:20px 20px;

  h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
  }

  .socialIcon{
    display: flex;
    gap: 10px;
    margin-top: 30pxw;

    a{
        width: 30px;
        height: 30px;
        background: var(--Main);
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            color: #fff;
        }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .bgShape{
        display: block !important;
    }
    .headerTitle{
        margin-top: 0px !important;
    }
    .contentBody{
        h5{
            padding: 0px 20px;
        }
        p{
            padding: 0px 20px;
        }

        .socialIcon{
            padding: 0px 20px;
          }
    }
}
