@import "../../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}
:global(.buttonWrapper) {
  display:none;

  // button {
  // }
}
.contentBody {
  margin-top: 20px !important;
  box-shadow: 0 0px 4px 1px #ebebeb;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  padding: 15px 0px;
  width: 460px;
    margin: 0 auto !important;

  .timeSlot {
    padding:0px 15px;

    h5 {
      font-size: 17px;
      margin-bottom: 0px;
      margin-top: 15px;
    }

    ul {
      list-style: none;
      margin: 0px -5px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;

      li {
        width: 33.33%;
        padding: 5px 5px;


        button {
          border: 1px solid var(--Main);
          background: #fff;
          color: var(--Main);
          padding: 5px 5px;
          border-radius: 5px;
          width: 100%;
          display: block;
          font-size: 13px;

          &:focus , &.active{
            background:var(--Main);
            color:#fff;
          }
        }
      }
    }
  }

  .rescheduleContent {
    padding: 0px 15px;
    list-style:none;
    margin:0;

    li {
       display:flex;
       justify-content: space-between;
       align-items: center;
       margin-bottom: 5px;

      h6 {
        font-size: 20px;
        color: var(--Main);
        margin-bottom: 0px;
      }
      p {
        font-size: 15px;
        margin: 0;

        &.classPrice {
          color:var(--Main);
          font-weight: 500;
        }
      }

      .qtyBox {
        display:flex;
     
        input {
          width: 25px;
          height: 25px;
          border: none;
          background: var(--Main);
          border-radius: 50px;
          margin: 0px 5px;
          color:#fff;
          font-weight: 500;
          font-size: 15px;
          text-align: center;
        }
        button {
          width: 25px;
          height: 25px;
          border: none;
          background: #D8E3FF;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color:var(--Main);
        }
      }
    }
  }
}

.continue{
  background-color: var(--Main) !important;
  color: #fff !important;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
}
/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
 
}
