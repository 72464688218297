@import "../../variables.module.scss";

.headerTitle {

  .titleBar {
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 0px 4px 1px #ebebeb;

    .back {
        color:#000 !important;
        font-size: 20px;
    }
    .headTitle {
      text-align:center;

      h6 {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}

.sliderHome {
  padding:10px 0px 0px 0px;

  
}

.detailsBox {

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
}
.bookBtn {
  background: var(--Main) !important;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff !important;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding: 0px;

  &:focus, &:hover {
    box-shadow: none;
    background-color: var(--Main2) !important;
    color:#fff !important;
}
}

.contentBody {
  padding:20px 20px;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
 
}
